<template>
  <div class="sctp-container mg-tb10 loginRestrictionsHelp">
    <div class="mg-b10">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>限制登录说明</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="10" type="flex">
      <el-col :span="18">
        <div class="sctp-bg-white">
          <template v-if="reasonConfig.detail">
            <div class="card-title pad-tb20">
              <div>
                <h1 class="mg-b5 pad-lr15">{{ reasonConfig.detail.reason }}</h1>
              </div>
            </div>
            <div class="pad20" style="min-height: 400px;">
              <div v-html="reasonConfig.detail.content" class="rich-text"></div>
            </div>
          </template>
        </div>
      </el-col>
      <el-col :span="6">
        <template v-if="reasonConfig.list && reasonConfig.list.length > 0">
          <div class="sctp-bg-white">
            <h3 class="sctp-bold sctp-tc card-title pad-tb15">账号违规情况说明</h3>
            <div class="pad15">
              <template v-for="(reason, reasonIndex) in reasonConfig.list">
                <div
                  @click="reasonConfig.onNoticeItemClick(reason)"
                  class="reason-item sctp-ellipsis">{{ reasonIndex + 1 }}、{{
                    dateToString(reason.createtime, '[MM/dd]')
                  }}
                  {{ reason.reason }}
                </div>
              </template>
            </div>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {common} from "@/apis/index";

export default {
  name: "loginRestrictionsHelp",
  props: ['id'],
  data() {
    return {
      reasonConfig: {
        detail: null,
        list: [],
        loadDetail: () => {
          common.loginRestrictionsDetail({id: this.id})
          .then(res => {
            let {retdata, list} = res;
            this.reasonConfig.detail = retdata;
            this.reasonConfig.list = list;
          })
        },
        onNoticeItemClick: (notice) => {
          this.goPage(`/loginRestrictionsHelp/${notice.id}`)
          .then(() => {
            this.reasonConfig.loadDetail();
          })
        },
      }
    }
  },
  beforeMount() {
    this.reasonConfig.loadDetail();
  }
}
</script>

<style scoped lang="scss">
.loginRestrictionsHelp ::v-deep {
  .card-title {
    background-color: #f9f9f9;
    text-align: center;
  }

  .reason-item {
    padding: 5px 0;
    cursor: pointer;
    &:hover {
      background-color: #F8F8F8;
    }
    &:not(:last-child) {
      border-bottom: 1px #ccc dashed;
    }
  }

}
</style>
